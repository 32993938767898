import { SARL } from "./champs_demandes/sarl";
import { SARLU } from "./champs_demandes/sarlu";
import { SAS } from "./champs_demandes/sas";
import { SASU } from "./champs_demandes/sasu";
import { ONG } from "./champs_demandes/ong";
import { SCOOP } from "./champs_demandes/scoop";
import { DOMICILIATION } from "./champs_demandes/domiciliation";
import { DEPOT_MARQUE } from "./champs_demandes/depot_marque";
import { TYPES_DEMANDE_CI } from "./champs_demandes/ci";
import { TYPES_DEMANDE_SN } from "./champs_demandes/sn";
import { TYPES_DEMANDE_GN } from "./champs_demandes/gn";
import { TYPES_DEMANDE_CM } from "./champs_demandes/cm";
import { TYPES_DEMANDE_TG } from "./champs_demandes/tg";
import { TYPES_DEMANDE_BF } from "./champs_demandes/bf";
import { TYPES_DEMANDE_BN } from "./champs_demandes/bn";
import { TYPES_DEMANDE_RDC } from "./champs_demandes/rdc";
import { TYPES_DEMANDE_MR } from "./champs_demandes/mr";
import { Dictionnaries } from "./dictionnaries";

export const Data = {
  ChampsDemande: {
    CI: { ...TYPES_DEMANDE_CI },
    SN: { ...TYPES_DEMANDE_SN },
    GN: { ...TYPES_DEMANDE_GN },
    CM: { ...TYPES_DEMANDE_CM },
    TG: { ...TYPES_DEMANDE_TG },
    BF: { ...TYPES_DEMANDE_BF },
    BN: { ...TYPES_DEMANDE_BN },
    MR: { ...TYPES_DEMANDE_MR },
    RDC: { ...TYPES_DEMANDE_RDC },
    SARL,
    SARLU,
    SAS,
    SASU,
    ONG,
    SCOOP,
    DOMICILIATION,
    DEPOT_MARQUE,
  },
  Dictionnaries,
};

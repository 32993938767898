import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Icons from "react-feather";
import ApiService from "../../api/services/api.service";
import Loading from "../../components/Loading";

function ListUtilisateur() {
  const [paginatePage, setPaginatePage] = useState({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const ELEMENT_PER_PAGE = 20;
  const [loading, setLoading] = useState(false);

  const [inputFields, setInputFields] = useState({
    denomination: "",
    numero_demande: "",
    pays_id: "",
  });

  const [listUtlisation, setListUtlisation] = useState([]);

  const apiUrl = new ApiService();

  const { id } = useParams();

  useEffect(() => {
    getListUtilisateurAgence();
  }, []);

  const getListUtilisateurAgence = async () => {
    setLoading(true);
    await apiUrl._get(`admin/organisation/${id}/users`).then((response) => {
      const { users } = response.data;
      setPaginatePage(response.data);
      setListUtlisation(users);
      const PAGE_NUMBER = Math.ceil(response.data.total / ELEMENT_PER_PAGE);
      setTotalPage(PAGE_NUMBER);
    });
    setLoading(false);
  };

  const handlePreviousPage = async () => {
    setLoading(true);
    if (totalPage > paginatePage.current_page) {
      page > 0 ? setPage(page - 1) : setPage(1);
      await apiUrl
        ._get(`admin/organisation/${id}/users?page=${page}`)
        .then((response) => {
          const { users } = response.data;

          setListUtlisation(users);
        });
    }
    setLoading(false);
  };
  const handleNextPage = async () => {
    setLoading(true);
    setPage(page + 1);
    await apiUrl
      ._get(`admin/organisation/${id}/users?page=${page}`)
      .then((response) => {
        const { users } = response.data;

        setListUtlisation(users);
      });

    setLoading(false);
  };

  const handleFormChange = async (event) => {
    setLoading(true);
    const { value } = event.target;
    if (value.length >= 3) {
      await apiUrl
        ._get(`admin/organisation/${id}/users?username=${value}`)
        .then((response) => {
          const { users } = response.data;
          console.log("users", users);

          setListUtlisation(users);
        });
    }
    if (!value) {
      await getListUtilisateurAgence();
    }
    setLoading(false);
  };

  return (
    <div>
      <div
        className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center my-2
                  justify-between"
      >
        <div className="p-5 text-xl  font-medium ">Liste des Utilisateurs</div>
      </div>

      <div className="intro-y">
        <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2">
          <div className="box p-2 chat">
            {/* onSubmit={handleFilterSubmit} */}
            <form className="flex-filter">
              <div className=" relative text-gray-700 dark:text-gray-300">
                <input
                  type="text"
                  name="username"
                  onChange={(e) => handleFormChange(e)}
                  className="input w-56 box ml-1 mr-1 pr-10 placeholder-theme-13"
                  placeholder="Recherche..."
                />
              </div>
              <div className=" w-45 mr-1 px-5   text-white flex gap-2">
                <Icons.Search
                  className="w-6 h-6 relative my-auto inset-y-0  right-0"
                  color="blue"
                />
              </div>
            </form>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center gap-8 text-lg my-8">
            <Loading color={"blue"} />
          </div>
        ) : (
          <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table className="table table-report mt-6">
              <thead>
                <tr className="">
                  <th>Nom & prénoms</th>
                  <th>Role</th>
                  <th>Agence</th>
                  <th className="">Statut</th>
                  <th className="">Contact</th>
                  <th className="">Email</th>
                </tr>
              </thead>
              <tbody>
                {listUtlisation &&
                  listUtlisation.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <p className="legafrik-list-data-text">
                          {item.username ? item.username : "non renseigné"}
                        </p>
                      </td>
                      <td>
                        <p className="legafrik-list-data-text">
                          {item.role.libelle}
                        </p>
                      </td>
                      <td>
                        <p className="legafrik-list-data-text">
                          {item.agence?.name
                            ? item?.agence.name
                            : "non definit"}
                        </p>
                      </td>
                      <td>
                        <p className="legafrik-list-data-text text-green-600">
                          Actif
                        </p>
                      </td>
                      <td>
                        <p className="legafrik-list-data-text">
                          {item.numero_telephone}
                        </p>
                      </td>
                      <td>
                        <p className="legafrik-list-data-text">{item.email}</p>
                      </td>
                    </tr>
                  ))}
                {!listUtlisation.length ? (
                  <tr>
                    <td className="text-2xl text-center col-12">
                      Pas d'agence enregistrer
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <div
              className={
                paginatePage.total > 20
                  ? "px-5 py-3 flex justify-end text-right border-t border-gray-200 "
                  : "hidden"
              }
            >
              <button
                onClick={handlePreviousPage}
                type="button"
                className="button  flex w-36 items-center  border bg-theme-1 text-white "
                disabled={paginatePage.current_page === 1}
              >
                <span>
                  <Icons.ArrowLeft />
                </span>
                <span> Precedent </span>
              </button>
              <div className="bg-white text-gray-600 bold mx-5 p-3 box ">
                {listUtlisation ? (
                  <span>
                    {paginatePage.current_page} / {totalPage}
                  </span>
                ) : (
                  0
                )}
              </div>
              <button
                onClick={handleNextPage}
                type="button"
                className="button  flex w-36 items-center  border bg-theme-1 text-white"
                disabled={totalPage === paginatePage.current_page}
              >
                <span> Suivant </span>
                <span>
                  <Icons.ArrowRight />
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListUtilisateur;

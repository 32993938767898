import Moment from "moment";
import { useState } from "react";
import { Utils } from "../../../../utils";

export function RecapStepScoop(props) {
    console.log("RecapStepScoop", props);

    const [isDisabled, setIsDisabled] = useState(false);

    const { parseJson, isJsonParseable } = Utils.String;
    const demande = props?.dataGlobal?.useDemande?.champs_demande
        ? JSON.parse(props.dataGlobal.useDemande.champs_demande)
        : null;
    const sigle = props?.questionnaireObject["sigle"]
        ? props?.questionnaireObject["sigle"]
        : null;
    const activites = props?.questionnaireObject["activites"]
        ? props?.questionnaireObject["activites"]
        : null;
    const associes = props?.questionnaireObject?.associes
        ? JSON.parse(props?.questionnaireObject?.associes)
        : [];

    const president = props.questionnaireObject?.president
        ? JSON.parse(props?.questionnaireObject?.president)
        : "{}";

    const presidentCommision = props.questionnaireObject["president-commission"]
        ? JSON.parse(props.questionnaireObject["president-commission"])
        : "{}";
    const rapporteurs = props.questionnaireObject.rapporteurs
        ? JSON.parse(props.questionnaireObject.rapporteurs)
        : [];

    // console.log("president", rapporteurs);

    const directeur = props?.questionnaireObject?.directeurs
        ? JSON.parse(props?.questionnaireObject?.directeurs)[0]
        : null;

    const adresse = props?.questionnaireObject?.adresse
        ? JSON.parse(props?.questionnaireObject?.adresse)
        : null;

    const handleStepSubmit = (e) => {
        setIsDisabled(true);

        e.preventDefault();
        props.handleSubmit(e, "", "");
    };

    const renderArray = (array) => {
        if (props?.dataGlobal != null) {
            let itemData = array;
            return itemData;
        }
    };

    const renderData = (items) => {
        if (
            props?.questionnaireObject != null &&
            items != null
        ) {
            let itemData = items;

            const rendDatas = Object.keys(itemData).map((item_field, index) => {
                let attribute = `${item_field[0].toUpperCase() + String(item_field).substring(1)
                    }`;
                attribute = attribute.replace(/_/g, " ");
                attribute = attribute.replace(/-/g, " ");
                if (
                    attribute !== "Type" &&
                    attribute !== "Fait partie des associes" &&
                    itemData[item_field] !== null &&
                    itemData[item_field] !== ""
                ) {
                    if (attribute === "Date naissance") {
                        return (
                            <div className="flex-item" key={item_field.toString()}>
                                <span className="flex-item-text">Date de naissance:</span>
                                <span className="flex-item-description">
                                    {Moment(
                                        itemData[item_field].toString().replace(/_/g, " ")
                                    ).format("DD/MM/YYYY")}
                                </span>
                            </div>
                        );
                    } else if (attribute === "Apport") {
                        return (
                            <div className="flex-item" key={item_field.toString()}>
                                <span className="flex-item-text">Apport:</span>
                                <span className="flex-item-description">
                                    {typeof itemData[item_field] !== "object"
                                        ? itemData[item_field].toString().replace(/_/g, " ")
                                        : itemData[item_field].toString()}
                                    FCFA
                                </span>
                            </div>
                        );
                    } else if (attribute === "Capital sociale") {
                        return (
                            <div className="flex-item" key={item_field.toString()}>
                                <span className="flex-item-text">Capital social:</span>
                                <span className="flex-item-description">
                                    {typeof itemData[item_field] !== "object"
                                        ? itemData[item_field].toString().replace(/_/g, " ")
                                        : itemData[item_field].toString()}
                                    FCFA
                                </span>
                            </div>
                        );
                    } else {
                        return (
                            <div className="flex-item" key={item_field.toString()}>
                                <span className="flex-item-text">
                                    {" "}
                                    {item_field === "denomination"
                                        ? "Nom de l'entreprise"
                                        : item_field === "secteur-activite"
                                            ? "Secteur d'activité"
                                            : item_field === "nationalite"
                                                ? "Nationalité"
                                                : item_field === "denomination"
                                                    ? "Nom entreprise 1"
                                                    : item_field === "denomination"
                                                        ? "Nom entreprise 2"
                                                        : item_field === "denomination"
                                                            ? "Nom entreprise 3"
                                                            : item_field === "date-naissance"
                                                                ? "Date de naissance"
                                                                : item_field === "date-reception-certificat"
                                                                    ? "Date de réception du registre de commerce"
                                                                    : item_field === "numero-telephone"
                                                                        ? "Numéro de téléphone"
                                                                        : item_field === "numero-telephone"
                                                                            ? "Numéro de téléphone"
                                                                            : item_field === "date-reception-certificat"
                                                                                ? "Date de réception du certificat"
                                                                                : item_field === "gestion-facture-normalisees"
                                                                                    ? "Gestion de factures normalisées"
                                                                                    : item_field === "gestion-dfe"
                                                                                        ? "Gestion de DFE"
                                                                                        : item_field === "prenoms"
                                                                                            ? "Prénom(s)"
                                                                                            : item_field === "type-nationalite"
                                                                                                ? "Type de nationalité"
                                                                                                : item_field === "type-mandat"
                                                                                                    ? "Type de mandat"
                                                                                                    : item_field === "ville-naissance"
                                                                                                        ? "Ville de naissance"
                                                                                                        : item_field === "ville_habitation"
                                                                                                            ? "Ville d'habitation"
                                                                                                            : item_field === "prenoms"
                                                                                                                ? "Prénom(s)"
                                                                                                                : item_field === "nom-pere"
                                                                                                                    ? "Nom du père"
                                                                                                                    : item_field === "nom-mere"
                                                                                                                        ? "Nom de la mère"
                                                                                                                        : item_field === "nombre-associes"
                                                                                                                            ? "Nombre d'associés"
                                                                                                                            : item_field === "entreprise-anterieur"
                                                                                                                                ? "Avez-vous une entreprise"
                                                                                                                                : item_field === "date-reception-registre-de-commerce"
                                                                                                                                    ? "Date de réception du registre de commerce"
                                                                                                                                    : item_field === "localisation-siege"
                                                                                                                                        ? "Siège de l'entreprise"
                                                                                                                                        : item_field === "capital-social"
                                                                                                                                            ? "Capital social"
                                                                                                                                            : item_field === "code-postale"
                                                                                                                                                ? "Numéro de boîte postale"
                                                                                                                                                : item_field === "nom-prenoms-proprietaire"
                                                                                                                                                    ? "Nom et prénom(s) du propriétaire"
                                                                                                                                                    : item_field === "numero-telephone"
                                                                                                                                                        ? "Numéro de téléphone"
                                                                                                                                                        : item_field === "lot"
                                                                                                                                                            ? "Numéro de lot"
                                                                                                                                                            : item_field === "ilot"
                                                                                                                                                                ? "Numéro d'ilot"
                                                                                                                                                                : item_field === "section"
                                                                                                                                                                    ? "Numéro de section"
                                                                                                                                                                    : item_field === "parcelle"
                                                                                                                                                                        ? "Numéro de parcelle"
                                                                                                                                                                        : item_field === "numero-piece-identite"
                                                                                                                                                                            ? "Numéro de pièce d'identité"
                                                                                                                                                                            : item_field === "date-naissance"
                                                                                                                                                                                ? "Date de naissance"
                                                                                                                                                                                : item_field === "lieu-naissance"
                                                                                                                                                                                    ? "Lieu de naissance"
                                                                                                                                                                                    : item_field === "pays-naissance"
                                                                                                                                                                                        ? "Pays de naissance"
                                                                                                                                                                                        : item_field === "situation-matrimoniale"
                                                                                                                                                                                            ? "Situation matrimoniale"
                                                                                                                                                                                            : item_field === "regime-matrimonial"
                                                                                                                                                                                                ? "Régime matrimonial"
                                                                                                                                                                                                : item_field === "denomination"
                                                                                                                                                                                                    ? "Nom de la société"
                                                                                                                                                                                                    : item_field === "forme-sociale"
                                                                                                                                                                                                        ? "Forme sociale"
                                                                                                                                                                                                        : item_field === "capital-social"
                                                                                                                                                                                                            ? "Capital social"
                                                                                                                                                                                                            : item_field === "siege-sociale"
                                                                                                                                                                                                                ? "Siège social"
                                                                                                                                                                                                                : item_field === "numero-sociale"
                                                                                                                                                                                                                    ? "Numéro RCCM"
                                                                                                                                                                                                                    : item_field === "ville-immatriculation"
                                                                                                                                                                                                                        ? "Ville d'immatriculation"
                                                                                                                                                                                                                        : item_field === "nom-representant"
                                                                                                                                                                                                                            ? "Nom du représentant"
                                                                                                                                                                                                                            : item_field === "prenoms-representant"
                                                                                                                                                                                                                                ? "Prénom(s) du représentant"
                                                                                                                                                                                                                                : item_field === "associe-gerant"
                                                                                                                                                                                                                                    ? "Associé gérant"
                                                                                                                                                                                                                                    : item_field === "fait-partie-des-associes"
                                                                                                                                                                                                                                        ? "Fait partie des associés"
                                                                                                                                                                                                                                        : item_field === "numero-telephone"
                                                                                                                                                                                                                                            ? "Numéro de téléphone"
                                                                                                                                                                                                                                            : item_field === "date-naissance"
                                                                                                                                                                                                                                                ? "Date de naissance"
                                                                                                                                                                                                                                                : item_field === "lieu-naissance"
                                                                                                                                                                                                                                                    ? "Lieu de naissance"
                                                                                                                                                                                                                                                    : item_field === "pays-naissance"
                                                                                                                                                                                                                                                        ? "Pays de naissance"
                                                                                                                                                                                                                                                        : item_field === "situation-matrimoniale"
                                                                                                                                                                                                                                                            ? "Situation matrimoniale"
                                                                                                                                                                                                                                                            : item_field === "regime-matrimonial"
                                                                                                                                                                                                                                                                ? "Régime matrimonial"
                                                                                                                                                                                                                                                                : item_field === "denomination"
                                                                                                                                                                                                                                                                    ? "Nom de la société"
                                                                                                                                                                                                                                                                    : item_field === "forme-sociale"
                                                                                                                                                                                                                                                                        ? "Forme sociale"
                                                                                                                                                                                                                                                                        : item_field === "capital-social"
                                                                                                                                                                                                                                                                            ? "Capital social"
                                                                                                                                                                                                                                                                            : item_field === "siege-sociale"
                                                                                                                                                                                                                                                                                ? "Siège social"
                                                                                                                                                                                                                                                                                : item_field === "numero-sociale"
                                                                                                                                                                                                                                                                                    ? "Numéro RCCM"
                                                                                                                                                                                                                                                                                    : item_field === "ville-immatriculation"
                                                                                                                                                                                                                                                                                        ? "Ville d'immatriculation"
                                                                                                                                                                                                                                                                                        : item_field === "nom-representant"
                                                                                                                                                                                                                                                                                            ? "Nom du représentant"
                                                                                                                                                                                                                                                                                            : item_field === "prenoms-representant"
                                                                                                                                                                                                                                                                                                ? "Prénom(s) du représentant"
                                                                                                                                                                                                                                                                                                : item_field === "associe-gerant"
                                                                                                                                                                                                                                                                                                    ? "Associé gérant"
                                                                                                                                                                                                                                                                                                    : item_field === "fait-partie-des-associes"
                                                                                                                                                                                                                                                                                                        ? "Fait partie des associés"
                                                                                                                                                                                                                                                                                                        : item_field === "numero-telephone"
                                                                                                                                                                                                                                                                                                            ? "Numéro de téléphone"
                                                                                                                                                                                                                                                                                                            : item_field === "date-naissance"
                                                                                                                                                                                                                                                                                                                ? "Date de naissance"
                                                                                                                                                                                                                                                                                                                : item_field === "lieu-naissance"
                                                                                                                                                                                                                                                                                                                    ? "Lieu de naissance"
                                                                                                                                                                                                                                                                                                                    : item_field === "pays-naissance"
                                                                                                                                                                                                                                                                                                                        ? "Pays de naissance"
                                                                                                                                                                                                                                                                                                                        : item_field === "situation-matrimoniale"
                                                                                                                                                                                                                                                                                                                            ? "Situation matrimoniale"
                                                                                                                                                                                                                                                                                                                            : item_field === "regime-matrimonial"
                                                                                                                                                                                                                                                                                                                                ? "Régime matrimonial"
                                                                                                                                                                                                                                                                                                                                : item_field === "denomination"
                                                                                                                                                                                                                                                                                                                                    ? "Nom de la société"
                                                                                                                                                                                                                                                                                                                                    : item_field === "denomination"
                                                                                                                                                                                                                                                                                                                                        ? "Nom de la société"
                                                                                                                                                                                                                                                                                                                                        : item_field === "forme-sociale"
                                                                                                                                                                                                                                                                                                                                            ? "Forme sociale"
                                                                                                                                                                                                                                                                                                                                            : item_field === "capital-social"
                                                                                                                                                                                                                                                                                                                                                ? "Capital social"
                                                                                                                                                                                                                                                                                                                                                : item_field === "siege-sociale"
                                                                                                                                                                                                                                                                                                                                                    ? "Siège social"
                                                                                                                                                                                                                                                                                                                                                    : item_field === "numero-sociale"
                                                                                                                                                                                                                                                                                                                                                        ? "Numéro RCCM"
                                                                                                                                                                                                                                                                                                                                                        : item_field === "ville-immatriculation"
                                                                                                                                                                                                                                                                                                                                                            ? "Ville d'immatriculation"
                                                                                                                                                                                                                                                                                                                                                            : item_field === "nom-representant"
                                                                                                                                                                                                                                                                                                                                                                ? "Nom du représentant"
                                                                                                                                                                                                                                                                                                                                                                : item_field === "prenoms-representant"
                                                                                                                                                                                                                                                                                                                                                                    ? "Prénom(s) du représentant"
                                                                                                                                                                                                                                                                                                                                                                    : item_field === "associe-gerant"
                                                                                                                                                                                                                                                                                                                                                                        ? "Associé gérant"
                                                                                                                                                                                                                                                                                                                                                                        : item_field === "fait-partie-des-associes"
                                                                                                                                                                                                                                                                                                                                                                            ? " Président Associé"
                                                                                                                                                                                                                                                                                                                                                                            : item_field === "fait-partie-des-associes"
                                                                                                                                                                                                                                                                                                                                                                                ? "Fait partie des associés"
                                                                                                                                                                                                                                                                                                                                                                                : item_field === "president-commission"
                                                                                                                                                                                                                                                                                                                                                                                    ? "président commission"
                                                                                                                                                                                                                                                                                                                                                                                    : item_field === "numero-telephone"
                                                                                                                                                                                                                                                                                                                                                                                        ? "Numéro de téléphone"
                                                                                                                                                                                                                                                                                                                                                                                        : item_field === "date-naissance"
                                                                                                                                                                                                                                                                                                                                                                                            ? "Date de naissance"
                                                                                                                                                                                                                                                                                                                                                                                            : attribute}
                                    :
                                </span>
                                <span className="flex-item-description">
                                    {typeof itemData[item_field] !== "object"
                                        ? itemData[item_field].toString().replace(/_/g, " ")
                                        : itemData[item_field].toString()}
                                </span>
                            </div>
                        );
                    }
                }
            });
            return rendDatas;
        }
    };

    return (
        <div
            className={`grid grid-cols-1 col-span-12 ${props.step !== props.index ? "hidden" : ""
                } `}
        >
            <>
                <div className="flex-content-column">
                    <div className="flex-content-item">
                        <div className="flex-content-item-header">
                            <h3>Demande</h3>
                        </div>
                        <div className="flex-content-item-body">{renderData(demande)}</div>
                    </div>
                    {props?.questionnaireObject && sigle ? (
                        <div className="flex-content-item border-t-2 border-theme-1 py-3">
                            <div className="flex-content-item-body">
                                <div className="flex-item font-bold">
                                    Sigle:
                                </div>
                                <div className="flex-item">
                                    <span className="flex-item-description">
                                        {isJsonParseable(sigle) ? parseJson(sigle)?.sigle : sigle}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {props?.questionnaireObject && president ? (
                        <div className="flex-content-item border-t-2 border-theme-1 py-8">
                            <div className="flex-content-item-header">
                                <h3>Président</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(president)}
                            </div>
                        </div>
                    ) : null}

                    {props?.questionnaireObject && presidentCommision ? (
                        <div className="flex-content-item border-t-2 border-b-2 border-theme-1 py-8">
                            <div className="flex-content-item-header">
                                <h3>Président de la commission</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(presidentCommision)}
                            </div>
                        </div>
                    ) : null}

                    {props?.questionnaireObject && Array.isArray(rapporteurs) && rapporteurs.length > 0 ? (
                        <div className="flex-content-item border-b-2 border-theme-1 py-8">
                            <div className="flex-content-item-header">
                                <h3>Rapporteurs</h3>
                            </div>
                            {rapporteurs.map((rapporteur, index) => (
                                <div key={index} className="flex-content-item-body-fex">
                                    <div className="flex-content-item-body-wrapper">
                                        <div className="item-body-wrapper-header">
                                            <h6 className="item-body-wrapper-header-title">
                                                Rapporteur {index + 1}:
                                            </h6>
                                        </div>
                                        <div className="flex-content-item-body-wrapper-mapper">
                                            {renderData(rapporteur)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}

                    {props?.questionnaireObject && directeur ? (
                        <div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Directeur</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(directeur)}
                            </div>
                        </div>
                    ) : null}

                    {props?.questionnaireObject && Array.isArray(associes) && associes.length > 0 ? (
                        <div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Associés</h3>
                            </div>
                            {associes.map((associe, index) => (
                                <div key={index} className="flex-content-item-body-fex">
                                    <div className="flex-content-item-body-wrapper">
                                        <div className="item-body-wrapper-header">
                                            <h6 className="item-body-wrapper-header-title">
                                                Associé {index + 1}:
                                            </h6>
                                        </div>
                                        <div className="flex-content-item-body-wrapper-mapper">
                                            {renderData(associe)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}

                    {props?.questionnaireObject && activites ? (
                        <div className="flex-content-item border-t-2 pt-4 border-theme-1">
                            <div className="flex-content-item-header">
                                <h3>Activités</h3>
                            </div>
                            <div className="flex-content-item-body">
                                <div className="flex-item">
                                    <span className="flex-item-description">{activites}</span>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {props?.questionnaireObject && adresse ? (
                        <div className="flex-content-item border-t-2 border-b-2 border-theme-1 py-4">
                            <div className="flex-content-item-header">
                                <h3>Adresse</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(adresse)}
                            </div>
                        </div>
                    ) : (
                        <div className="flex-content-item border-theme-1">
                            <div className="flex-content-item-header">
                                <h3>Adresse</h3>
                            </div>
                            <div className="flex-content-item-body">
                                <div className="flex-item">
                                    <span className="flex-item-description">
                                        Domicilié à Legafrik
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <div
                    className="intro-y col-span-12 flex items-center justify-center
    sm:justify-end mt-10"
                >
                    <button
                        className="button w-24 justify-center block bg-gray-200 text-gray-600 
    dark:bg-dark-1 dark:text-gray-300"
                        onClick={props.handleQuestionnaireBackClick ?? null}
                    >
                        Revenir
                    </button>
                    <button
                        className="button w-24 justify-center block bg-theme-1 
    text-white ml-2"
                        onClick={handleStepSubmit}
                        disabled={isDisabled}
                    >
                        {isDisabled ? " Chargement en cours..." : "Suivant"}
                    </button>
                </div>
            </>
        </div>
    );
}

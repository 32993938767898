import { Fragment, useState, useMemo, useEffect } from "react";
import { Components } from ".";
import * as Icons from "react-feather";
import { Utils } from "../utils";
import { Services } from "../services";
import { liste_bank } from "../data/champs_demandes/ci/liste_bank";
import { countryCodes } from "./CountryCode/CountryCodes";
import { dataDataGreffeNom } from "../services/DatagreffeService";
import { Accordion, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "./Loading";


export function DemandeStep(props) {
  console.log("DemandeStep", props);
  const [choice, setChoice] = useState("");
  const [selectChoice, setSelectChoice] = useState({
    response: [],
  });
  const [inputPhoneNumber, setInputPhoneNumber] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [choiceContrat, setChoiceContrat] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [showVille, setShowVille] = useState(false);
  const [bank, setBank] = useState("");

  const [showNomEntreprise, setShowNomEntreprise] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [selectedCountryCode, setSelectedCountryCode] = useState("");


  const [, setChecked1] = useState(false);
  const [, setChecked2] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [associes, setAssocies] = useState([]);
  const [fraisCession, setFraisCession] = useState(0);
  const [dureeContartBail, setDureeContartBail] = useState(0);
  const [nomEntreprise, setNomEntreprise] = useState("");
  const [pourcentagePart, setPourcentagePart] = useState(0);
  const [newDenomination, setNewDenomination] = useState("");

  const [showFrais, setShowFrais] = useState(false);
  const [, setSituation_matri] = useState("");
  const [nvActivite, setNvActivite] = useState("");
  const [nvPresident, setPresident] = useState({});
  const [nvLoyer, setNvLoyer] = useState(0);
  const [nouveauSieges, setNouveauSiege] = useState("");
  const [fraisBail, setFraisBail] = useState(0);
  const [dataFrais, setDatafrais] = useState({});
  const [currency, setCurrency] = useState(false);
  const [dataFraisCapital, setDatafraisCapital] = useState({});
  const [fraisCapital, setFraisCapital] = useState(0);
  const [nombreAssocies, setNombreAssocies] = useState(0);
  const [itemdata, setItemdata] = useState({});
  const [loading, setloading] = useState({});
  const [isLastStepLoading, setIsLastStepLoading] = useState(false);





  const [isOther, setIsOther] = useState(false);
  const [dataEnterprise, setDataEnterprise] = useState(null);
  const [undefinedState, setUndefinedState] = useState(false);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const abortController = useMemo(() => new AbortController(), []);

  const defaultFormData = {
    "numero-rccm-nom-entreprise": "",
  };


  const [formData, setFormData] = useState(defaultFormData);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  if (
    props.champsDemandeData.name.includes("secteur-activite") &&
    (props.demande.includes("sci") || props.demande.includes("SCI"))
  ) {
    let data = props.champsDemandeData.options.filter((option, index) =>
      option.value.includes("Activités")
    );

    data[1] = {
      value: "Aménagement foncier",
      price: "",
    };
    props.champsDemandeData.options = data;
  }

  const handleCheck = (event) => {
    if (
      props.champsDemandeData.name === "email" &&
      !isValidEmail(event.target.value)
    ) {
      setError("Votre email n'est pas valable");
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };



  const handleNextClick = async (e) => {

    setIsDisabled(true);
    //console.log('choice', choice);
    e.preventDefault();
    setloading(true); // Active le chargement
    setIsDisabled(false);
    // Désactiver le bouton après un clic
    setIsDisabled(true);


    // Vérification globale si aucune valeur n'est saisie
    if (!choice) {
      toast.error("Veuillez entrer une valeur avant de continuer.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsDisabled(false); // Réactive le bouton
      return;
    }

    if (choice === "Autres") {
      toast.error("Vous devez entrer votre choix.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsDisabled(false); // Réactive le bouton
      return;
    }

    if (props.champsDemandeData.name === "prenom") {
      let payload = {
        numero_telephone: props.champsDemandeObject["numero-telephone"],
        email: props.champsDemandeObject["email"],
        username: props.champsDemandeObject["nom"] + " " + choice,
        password: "LegafrikV3",
      };

      try {
        const { data, status, message } =
          await Services.DemandeService.verificationUser(
            JSON.stringify(payload),
            abortController.signal
          );
        const dataUser = {
          dossier_id: data.dossier_id,
          token: data.token,
          user_id: data.user_id,
          email: props.champsDemandeObject["email"],
        };
        window.localStorage.setItem("dataUser", JSON.stringify(dataUser));
        setIsDisabled(true);

        /*        if (status === 200 && message.includes("existant")) {
          Swal.fire({
            allowOutsideClick: false,
            title: "Information",
            text: "Votre compte client existe déjà veuillez terminer la procédure au niveau de la plateforme cliente.",
            icon: "info",
            confirmButtonColor: "#006699",
            confirmButtonText: "Continuer la procédure",
          }).then((result) => {
            window.localStorage.clear();
            navigate("/connexion");
          });
        }*/
      } catch (error) {
        setIsDisabled(true);
      }
    }

    setIsModalOpen(false);
    //console.log(props.champsDemandeData);
    if (props.champsDemandeData.name === "denomination") {
      let denomination = props.champsDemandeObject["denomination"];
      let denominationArray =
        denomination && denomination.length > 0 ? denomination : [];

      denominationArray.push(choice);
      //console.log("denominationArray", denominationArray);
      props.handleNextClick(e, "denomination", denominationArray);
      return;
    }

    if (props.handleManualInput) {
      props.handleManualInput(props.champsDemandeData.name, inputValue);
    }

    if (props.champsDemandeData.name === "numero-telephone") {
      if (!selectedCountryCode) {
        setError("Veuillez sélectionner un indicatif de pays.");
        setIsDisabled(false);
        return;
      }

      const cleanNumber = choice.replace(/\s+/g, '');
      const regex = new RegExp(`^(${selectedCountryCode.replace('+', '\\+')})`);
      const formattedPhoneNumber = cleanNumber.match(regex)
        ? cleanNumber
        : `${selectedCountryCode}${cleanNumber}`;
      //console.log("le numero est : ", formattedPhoneNumber);

      props.handleNextClick(e, props.champsDemandeData.name, formattedPhoneNumber);
      setIsDisabled(false);
      return;
    }


    if (props.champsDemandeData.name === "type-employeur") {
      let send = {
        "type-employeur": choice,
        denomination: choiceContrat,
      };

      props.handleNextClick(e, "contrat-cdi-cdd", send);
      return;
    }
    if (props.champsDemandeData.name === "nouvelle-capital") {
      let nouveauCapital = props.champsDemandeObject["nouvelle-capital"];
      let nouveauCapitalArray =
        nouveauCapital && nouveauCapital.length > 0 ? nouveauCapital : [];

      nouveauCapitalArray.push(fraisCapital);
      props.handleNextClick(e, "nouvelle-capital", ...nouveauCapitalArray);
      return;
    }

    if (props.champsDemandeData.name === "vendeur-part") {
      // setAssocies(...associes, {
      //   "pourcentage-parts": pourcentagePart,
      //   "actionnaire-parts": nombreAssocies,
      // });
      props.handleNextClick(e, "associes", JSON.stringify(associes));

      return;
    }
    if (props.champsDemandeData.name === "nouveau-nom-entreprise") {
      props.handleNextClick(
        e,
        "nouveau-nom-entreprise",
        JSON.stringify(nomEntreprise)
      );

      return;
    }

    if (props.champsDemandeData.name === "nouvelle-activite") {
      props.handleNextClick(e, "nouvelle-activite", JSON.stringify(nvActivite));

      return;
    }

    if (props.champsDemandeData.name === "nouveau-president") {
      // console.log('nouveau-president',nvPresident);
      props.handleNextClick(
        e,
        "nouveau-president-gerant",
        JSON.stringify(nvPresident)
      );

      return;
    }

    // AJOUT 

    if (props.champsDemandeData.name === "prenom") {
      let payload = {
        numero_telephone: props.champsDemandeObject["numero-telephone"],
        email: props.champsDemandeObject["email"],
        username: props.champsDemandeObject["nom"] + " " + choice,
        password: "LegafrikV3",
      };

      try {
        const { data, status, message } = await Services.DemandeService.verificationUser(
          JSON.stringify(payload),
          abortController.signal
        );
        const dataUser = {
          dossier_id: data.dossier_id,
          token: data.token,
          user_id: data.user_id,
          email: props.champsDemandeObject["email"],
        };
        window.localStorage.setItem("dataUser", JSON.stringify(dataUser));
        setIsDisabled(true);
        setIsLastStepLoading(true)


      } catch (error) {
        setIsDisabled(true);
      }
      return;  // Ajoutez un `return` pour éviter de continuer après cette étape
    }



    if (props.champsDemandeData.name === "denomination") {
      let denomination = props.champsDemandeObject["denomination"];
      let denominationArray = denomination && denomination.length > 0 ? denomination : [];

      denominationArray.push(choice);
      props.handleNextClick(e, "denomination", denominationArray);
      return;
    }


    if (props.champsDemandeData.name === "type-employeur") {
      let send = {
        "type-employeur": choice,
        denomination: choiceContrat,
      };
      props.handleNextClick(e, "contrat-cdi-cdd", send);
      return;
    }



    //AJOUT 

    if (props.champsDemandeData.name === "modification-du-statut") {
      let tab = [];
      let tabFilter = selectChoice.response.filter((val, index) => {
        if (!tab.includes(val)) return tab.push(val);
      });

      window.localStorage.setItem("cst", JSON.stringify(tabFilter));
      props.handleNextClick(e, "modification-du-statut", tabFilter);
      return;
    }

    setChoice(choice);


    if (props.index === props.length) {
      setIsLastStepLoading(true);

      props.handleNextClick(e, props.champsDemandeData.name, choice);

      setTimeout(() => {
        setIsLastStepLoading(false);
        setIsDisabled(false);
      }, 60000);
      return;
    }


    props.handleNextClick(e, props.champsDemandeData.name, choice);
    setIsDisabled(true
    );


  };

  const handleChecked = (event) => {
    const { name, value } = event.target;
    // console.log("Checked", name, value);
    if (value) {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmitChecked = async (event) => {
    event.preventDefault();
    setIsLastStepLoading(true);
    setIsDisabled(true);
    try {
      const itemData = await dataDataGreffeNom(
        formData["numero-rccm-nom-entreprise"]
      );
      console.log("itemData", itemData);
      if (itemData) {
        setDataEnterprise(itemData);

      }
      if (itemData?.status !== 200 || !itemData?.response?.data) {


        setUndefinedState(true);

      }
    } catch (error) {
      // console.log("error", error);
      console.log(error);
    } finally {
      setFormData(defaultFormData);
      setloading(false); // Désactive le chargement
      setIsDisabled(false);
    }
    setIsDisabled(false);
  };

  function getTotalFraisCession() {
    let som = 0;
    associes.map((associe) => {
      // console.log("associe", associe);
      if (associe["montant-part-vendu"]) {
        // console.log("azer", associe["montant-part-vendu"]);
        som = som + JSON.parse(associe["montant-part-vendu"]);
      }

      // return { ...associe };
    });
    // console.log("som", som * 0.03);

    const result = Math.ceil(som * 0.03);
    if (result > 22000) {
      setFraisCession(Math.ceil(som * 0.03));
    } else {
      setFraisCession(22000);
    }
    // setFraisCession(result);
  }

  function getTotalFraisBail() {
    // const values = Object.values(dataFrais);
    // console.log("duree-contrat-bail", dataFrais["duree-contrat-bail"]);
    // console.log("nouveau-loye", dataFrais["nouveau-loyer"]);
    if (!showFrais) {
      setFraisBail(0);
    } else {
      const total =
        isNaN(dataFrais["duree-contrat-bail"]) ||
          isNaN(dataFrais["nouveau-loyer"])
          ? 0
          : dataFrais["duree-contrat-bail"] *
          dataFrais["nouveau-loyer"] *
          0.025;

      // console.log("total",total);
      if (total <= 22000) {
        setFraisBail(22000);
      } else {
        setFraisBail(total);
      }
    }
  }

  function getTotalCapital() {
    // const values = Object.values(dataFraisCapital);
    // console.log('dataFraisCapital',dataFraisCapital['nouvelle-capital']);
    let string = props.champsDemandeObject["capital-social"];
    const substring = "FCFA";
    if (string?.includes(substring)) {
      string = string.replace(/\s/g, "");
      setCurrency(true);
    }

    // console.log("string", string);
    const ancienCapital = parseInt(string);
    const total = isNaN(dataFraisCapital["nouvelle-capital"])
      ? 0
      : Math.ceil(
        (dataFraisCapital["nouvelle-capital"] - ancienCapital) * 0.03
      );
    // console.log("total", total);

    if (total <= 22000) {
      setFraisCapital(22000);
    } else {
      setFraisCapital(total);
    }
  }

  useEffect(() => getTotalFraisBail(), [dataFrais, showFrais]);
  useEffect(() => getTotalCapital(), [dataFraisCapital]);
  useEffect(() => getTotalFraisCession());

  useEffect(() => {
    if (!nombreAssocies) return;
    if (nombreAssocies <= 50) {
      setAssocies(Array(parseInt(nombreAssocies)).fill({}));
    }
  }, [nombreAssocies]);

  const handleInputChange = (index, name, value) => {
    if (name === "situation-matrimoniale") {
      if (value === "celibataire") {
        setChecked1(true);
        setChecked2(false);
      } else {
        setChecked2(true);
        setChecked1(false);
      }

      setSituation_matri(value);
    }

    if (props.champsDemandeData.name === "nouvelle-capital") {
      setDatafraisCapital({ ...dataFraisCapital, [name]: +parseInt(value) });
    }
    if (props.champsDemandeData.name === "nouveau-siege") {
      setDatafrais({ ...dataFrais, [name]: +parseInt(value) });

      // console.log("saetdada", dataFrais);
      if (name === "nouveau-loyer") {
        setNvLoyer(value);
      }

      if (name === "duree-contrat-bail") {
        setDureeContartBail(value);
      }
      if (name === "localisation-nouveau-siege") {
        setNouveauSiege(value);
      }
      // console.log("name", name, value);
    }
    if (
      props.champsDemandeData.name === "nouveau-nom-entreprise" &&
      name === "nouveau-nom-entreprise"
    ) {
      setNomEntreprise(value);
    }
    if (
      props.champsDemandeData.name === "nouvelle-activite" &&
      name === "nouvelle-activite"
    ) {
      setNvActivite(value);
    }

    if (props.champsDemandeData.name === "nouveau-president") {
      // console.log("name",name, value);
      if (name === "nom-nouveau-dirigeant") {
        setPresident({ ...nvPresident, [name]: value });
        // console.log("nouveau-dirigeant",value);
      }
      if (name === "prenoms-nouveau-dirigeant") {
        setPresident({ ...nvPresident, [name]: value });
        // console.log("prenoms-nouveau-dirigeant",value);
      }

      if (name === "civilite") {
        setPresident({ ...nvPresident, [name]: value });
        // console.log("prenoms-nouveau-dirigeant",value);
      }
    }

    if (
      props.champsDemandeData.name === "vendeur-part" &&
      name !== "pourcentage-parts" &&
      name !== "actionnaire-parts"
    ) {
      // console.log("vendeur-par", index, name, value);
      // setDatafraisCession({ ...dataFraisCession, [name]: +parseInt(value) });
      const associesCopy = associes.map((associe, idx) => {
        // console.log("associe", associe);

        return { ...associe };
      });
      associesCopy[index][name] = value;
      associesCopy[index]["actionnaire-parts"] = nombreAssocies;
      associesCopy[index]["pourcentage-parts"] = pourcentagePart;

      setAssocies(associesCopy);
    }
    // console.log("associes", associes);

    if (props.champsDemandeData.name === "verifiEntreprise") {
      if (name === "numero-rccm-nom-entreprise") {
        // setFormData(value);
        setNewDenomination(value);
        // console.log("name", name, value);
      }
    }

    if (name === "contrat-bail-impot" && value === "non") {
      setShowFrais(true);
      // setFraisCapital(0)
    }
    if (name === "contrat-bail-impot" && value === "oui") {
      setShowFrais(false);
    }
  };

  const handleModalClose = (e) => {
    setIsModalOpen(false);
    // setMoyen_paiement('');
  };

  const handleChoiceChange = (e, value) => {
    if (props.champsDemandeData.name === "modification-du-statut") {
      const { value, checked } = e.target;

      const { response } = selectChoice;

      if (checked) {
        let tab = {
          response: [...response, value],
        };
        setSelectChoice(tab);
        // console.log("tab1", tab);
      } // Case 2  : The user unchecks the box
      else {
        let tab = {
          response: response.filter((e) => e !== value),
        };
        setSelectChoice(tab);
        // console.log("tab2", tab);
      }
    }

    if (value.includes("A mon domicile") || value.includes("Je n'ai pas de siège")) {
      setIsModalOpen(true);
      setModalTitle(value);
      if (value.includes("A mon domicile")) {
        setModalContent("Nous aurons obligatoirement besoin de votre contrat de Bail, ou l'attestation de propriété avec la mention du nom du propriétaire.");
      } else if (value.includes("Je n'ai pas de siège")) {
        setModalContent("Vous devez obligatoirement trouver un siège pour les formalités de créations de votre entreprise.");
      }
    }

    if (value === "Autres") {
      setIsOther(true);
      setShowVille(false);
      setChoice("");
    } else if (value === "Abidjan") {
      setShowVille(true);
      setIsOther(false);
    } else if (value === "Entreprise") {
      setShowNomEntreprise(true);
      setChoice(value);
      setIsOther(false);
    } else {
      if (
        value.includes("A mon domicile") ||
        value.includes("Je n'ai pas de siège")
      ) {
        setIsModalOpen(true);
        setModalTitle(value);
        // if (value.includes("A mon domicile")) {
        //   setModalContent(
        //     "Nous aurons obligatoirement besoin de votre contrat de Bail, ou l attestation de propriété avec la mention du nom du propriétaire"
        //   );
        // } else 
        if (value.includes("Je n'ai pas de siège")) {
          setModalContent(
            "Vous devez obligatoirement trouver un siège pour les formalités de créations de votre entreprise"
          );
        }
      }

      Utils.String.parseBool(value);

      setIsOther(false);
      setShowVille(false);
      setShowNomEntreprise(false);
      setChoice(value);
    }
  };



  return (
    <div>
      <div className={props.step !== props.index ? "hidden" : ""}>
        <div className="px-5 mt-10">
          <div className="font-medium text-center text-lg">
            {props.champsDemandeData.question ?? ""}
          </div>
          <div className="text-gray-600 text-center mt-2">
            {props.champsDemandeData.description ?? ""}
          </div>
        </div>
        <div
          className={`px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
            dark:border-dark-5 demande-step`}
        >
          <div className="flex flex-col max-w-md mx-auto">
            {/* {props.champsDemandeData.demande.includes('sci-civile')} */}
            {props.champsDemandeData.options &&
              props.champsDemandeData.options.map((option, index) => {
                return (
                  <Fragment key={index}>
                    <Components.DemandeStepOptionItem
                      itemName={props.champsDemandeData.name}
                      itemValue={option.value}
                      demande={props.demande}
                      handleChoiceChange={(e) =>
                        handleChoiceChange(e, option.value)
                      }
                    />
                    {isModalOpen &&
                      !props.demande.includes("ong") &&
                      !props.demande.includes("association") &&
                      !props.demande.includes("fondation") &&
                      !props.demande.includes("Fondation") &&
                      !props.demande.includes("ONG") &&
                      !props.demande.includes("Association") ? (
                      <Components.ModalCustom
                        handleModalClose={handleModalClose}
                        title={modalTitle}
                        handleModalValidate={null}
                      >
                        <div>
                          <div className="flex items-center justify-center sm:justify-center">
                            <Icons.Info className="w-10 h-10 " />
                          </div>

                          {modalContent}
                          <div className="col-span-12 flex items-center justify-center sm:justify-end mt-10">
                            <button
                              className={`button w-24 justify-center block ml-2 ${isDisabled ? "bg-gray-400 text-gray-700" : "bg-theme-1 text-white"
                                }`}
                              onClick={handleNextClick}
                              disabled={isDisabled}
                            >
                              {isDisabled ? "Chargement..." : "Suivant"}
                            </button>
                          </div>
                        </div>
                      </Components.ModalCustom>
                    ) : null}
                  </Fragment>
                );
              })}
            {props.demande.includes("statutaire") ? (
              <div className="grid grid-cols-12 gap-4 row-gap-5">
                {props.champsDemandeData.name === "connaissance" ? (
                  <div className="grid grid-cols-12 col-span-12  gap-4">
                    <form
                      className="intro-y col-span-12"
                      onSubmit={handleSubmitChecked}
                    >
                      <div className="intro-y col-span-12">
                        {props.champsDemandeData.description}
                      </div>
                      <div className="intro-y col-span-12 flex items-center justify-center">
                        <input
                          type="text"
                          className="input w-full border  col-span-8"
                          name="numero-rccm-nom-entreprise"
                          placeholder="Le nom ou le numéro RCCM de l'entreprise"
                          onChange={(e) => handleChecked(e)}
                        />
                        <button
                          className={`button w-24 justify-center block ml-2 ${isDisabled ? "bg-gray-400 text-gray-700" : "bg-theme-1 text-white"
                            }`}
                          onClick={handleNextClick}
                          disabled={isDisabled}
                        >
                          {isDisabled ? "Chargement..." : "Suivant"}
                        </button>
                      </div>
                    </form>

                    <div className="intro-y col-span-12 font-medium ">
                      {dataEnterprise && dataEnterprise.response.data ? (
                        <>
                          {dataEnterprise.response.data.map((item, index) => {
                            return (
                              <div
                                className="intro-y col-span-12 shadow-2xl p-4 my-2 box border-0  zoom-in"
                                key={index}
                                onClick={(e) =>
                                  props.handleNextClicked(
                                    e,
                                    "dataApidatagreffe",
                                    item
                                  )
                                }
                              >
                                <div className="grid grid-cols-12">
                                  <div className="intro-y col-span-12 md:col-span-11 ">
                                    <div className="intro-y col-span-12 sm:col-span-6 text-2xl">
                                      {item.denomination}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                      <span className="text-lg">
                                        RCCM: {item.rccm}
                                      </span>
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                      <span className="text-lg">
                                        Nom commercial:
                                      </span>
                                      {item.nom_commercial}
                                    </div>
                                  </div>
                                  <div className="intro-y col-span-12 md:col-span-1  my-12">
                                    <Icons.ChevronRight />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}

                      {dataEnterprise &&
                        (dataEnterprise.status !== 200 ||
                          (dataEnterprise.response?.data &&
                            dataEnterprise.response?.data.length === 0)) ? (
                        <>
                          <div className="intro-y col-span-12 text-center ">
                            <Alert variant="danger">
                              Les informations de votre entreprise ne figurent pas
                              dans la base de données de notre partenaire
                              <Link to="https://datagreffe.ci/" target="_blank">
                                <b>
                                  <i>
                                    <u>datagreffe</u>
                                  </i>
                                </b>
                              </Link>
                              . <br /> Veuillez les saisir manuellement pour
                              continuer.
                            </Alert>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {props.champsDemandeData.name === "nouveau-siege" ? (
                  <div className="intro-y  col-span-12 text-md">
                    <div className="intro-y col-span-6 text-2xl my-5 text-center">
                      L'ancien siège de l'entreprise : <br />
                      <span className="font-medium ">
                        {props.champsDemandeObject["localisation-siege"]}
                      </span>
                    </div>
                    <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 ">
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                          Quel est le montant mensuel du loyer de votre nouveau
                          siège?
                        </div>
                        <input
                          type="number"
                          min={0}
                          className="input w-full border flex-1 field"
                          placeholder="le montant mensuel du nouveau loyer "
                          onChange={(e) =>
                            handleInputChange(
                              null,
                              "nouveau-loyer",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                          Quelle est la durée de votre nouveau contrat de bail (en
                          Mois)?
                        </div>
                        <input
                          type="number"
                          className="input w-full border flex-1 field"
                          placeholder="durée de votre contrat de bail (en Mois)"
                          onChange={(e) =>
                            handleInputChange(
                              null,
                              "duree-contrat-bail",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                          Ou se trouve le nouveau siège de votre entreprise ?
                        </div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="nouveau siège de votre entreprise"
                          onChange={(e) =>
                            handleInputChange(
                              null,
                              "localisation-nouveau-siege",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-12">
                        <div className="mb-2">
                          Avez-vous enregistré ce contrat aux impôts?
                        </div>
                        <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 ">
                          <div className="intro-y col-span-12 sm:col-span-2 ">
                            <span className="mb-2 mr-3 font-medium">Oui</span>
                            <input
                              type="radio"
                              name="Contrat-bail-impot"
                              value="oui"
                              className="input  border flex-1 field "
                              onChange={(e) =>
                                handleInputChange(
                                  null,
                                  "contrat-bail-impot",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="intro-y col-span-12 sm:col-span-6">
                            <span className="mb-2 mr-3 font-medium">Non</span>
                            <input
                              type="radio"
                              name="Contrat-bail-impot"
                              value="non"
                              className="input w-full border flex-1 field"
                              onChange={(e) =>
                                handleInputChange(
                                  null,
                                  "contrat-bail-impot",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 ">
                      {showFrais ? (
                        <div className="intro-y col-span-12 font-medium ext-md text-center">
                          Vous devez prévoir des frais d’enregistrement du contrat
                          de bail aux impôt de :
                          <span className="font-medium text-xl">
                            {fraisBail} F CFA
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {props.champsDemandeData.name === "nouvelle-activite" &&
                  props.champsDemandeObject.activites ? (
                  <div className="intro-y col-span-12 font-medium ext-md text-center">
                    <ul className="text-justify">
                      {props.champsDemandeObject?.activites.map((item, idx) => (
                        <li key={idx}>
                          <span className="font-medium  ">
                            {idx + 1} - {item.libele_activites}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-4 text-lg text-center">
                      Veuillez lister vos nouvelles activités?
                    </div>
                    <textarea
                      placeholder="Votre réponse..."
                      className={`input w-full border flex-1 field`}
                      rows="5"
                      cols="33"
                      onChange={(e) =>
                        handleInputChange(
                          null,
                          "nouvelle-activite",
                          e.target.value
                        )
                      }
                    />
                  </div>
                ) : null}

                {props.champsDemandeData.name === "nouvelle-activite" &&
                  !props.champsDemandeObject.activites ? (
                  <div className="intro-y col-span-12 font-medium ext-md text-center">
                    <div className="mt-4 text-lg text-center">
                      Veuillez lister vos nouvelles activités?
                    </div>
                    <textarea
                      placeholder="Votre réponse..."
                      className={`input w-full border flex-1 field`}
                      rows="5"
                      cols="33"
                      onChange={(e) =>
                        handleInputChange(
                          null,
                          "nouvelle-activite",
                          e.target.value
                        )
                      }
                    />
                  </div>
                ) : null}

                {props.champsDemandeData.name === "nouveau-nom-entreprise" ? (
                  <div className="intro-y col-span-12">
                    <div className="intro-y col-span-12 text-lg ">
                      <span className="font-medium intro-y ">
                        Le nom de l'entreprise est :
                      </span>
                      <span className="font-medium intro-y mx-4">
                        {props.champsDemandeObject.denomination}
                      </span>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="my-2">
                        Quel nom voulez-vous donner à votre entreprise
                      </div>
                      <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Entrez le nom"
                        onChange={(e) =>
                          handleInputChange(
                            null,
                            "nouveau-nom-entreprise",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                ) : null}

                {props.champsDemandeData.name === "nouvelle-capital" ? (
                  <div className="intro-y col-span-12">
                    <div className="intro-y col-span-12 text-lg text-center">
                      L'ancien capital était :
                      <span className="font-medium">
                        {currency
                          ? props.champsDemandeObject["capital-social"]
                          : props.champsDemandeObject["capital-social"] + "FCFA"}
                      </span>
                    </div>

                    <div className="my-4 text-lg text-center">
                      {props.champsDemandeData.description}
                    </div>
                    <input
                      type="number"
                      placeholder="Enter le nouveau capital"
                      className={`input w-full border flex-1 field`}
                      onChange={(e) =>
                        handleInputChange(
                          null,
                          "nouvelle-capital",
                          e.target.value
                        )
                      }
                    />
                    <div className="intro-y col-span-12 font-medium ext-md text-center">
                      Vous devez prévoir des frais d’enregistrement des actes
                      d’augmentation de capital de :
                      <span className="font-medium text-xl">
                        {fraisCapital} CFA
                      </span>
                    </div>
                  </div>
                ) : null}

                {props.champsDemandeData.name === "vendeur-part" ? (
                  <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 ">
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2 text-center">
                        Quel est le pourcentage de parts cédées (Ex 30%) ?
                      </div>
                      <input
                        type="number"
                        className="input w-full border flex-1 field"
                        placeholder="Pourcentage de part"
                        onChange={(e) =>
                          handleInputChange(
                            null,
                            "pourcentage-parts",
                            setPourcentagePart(e.target.value)
                          )
                        }
                      />
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2 text-center">
                        Combien d’actionnaires aimeraient vendre des parts ?
                      </div>
                      <input
                        type="number"
                        className="input w-full border flex-1 field"
                        placeholder="Nombre actionnaire"
                        onChange={(e) =>
                          handleInputChange(
                            null,
                            "actionnaire-parts",
                            setNombreAssocies(e.target.value)
                          )
                        }
                      />
                    </div>
                    {nombreAssocies && nombreAssocies > 50 ? (
                      <div className="intro-y col-span-12 text-center ">
                        <Alert variant="danger">
                          Le nombre d'associés ne doit pas dépasser 50 personnes !
                        </Alert>
                      </div>
                    ) : null}

                    {associes.map((associe, index) => {
                      return (
                        <div className="intro-y col-span-12" key={index}>
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                Qui est le vendeur {index + 1}
                              </Accordion.Header>
                              <Accordion.Body>
                                <div
                                  className="intro-y col-span-12"
                                  key={index}
                                  id={`${index}`}
                                >
                                  <div
                                    className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 items-center`}
                                  >
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                      <div className="mb-4">Nom et Prénom(s)</div>
                                      <input
                                        type="text"
                                        className="input w-full border flex-1 field "
                                        placeholder="Nom et Prénom(s)"
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "nom-prenom-vendeur",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="intro-y col-span-12 sm:col-span-6">
                                      <div className=" text-center">
                                        Quel est le montant des parts qu’il
                                        souhaite vendre ?
                                      </div>
                                      <input
                                        type="number"
                                        className="input w-full border flex-1 field"
                                        placeholder="montant des parts"
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "montant-part-vendu",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      );
                    })}
                    <div className="intro-y col-span-12 font-medium ext-md text-center">
                      Vous devez prévoir des frais d’enregistrement des actes de
                      cession de part de :
                      <span className="font-medium text-xl">
                        {fraisCession} F CFA
                      </span>
                    </div>
                  </div>
                ) : null}

                {props.champsDemandeData.name === "nouveau-president" ? (
                  <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 ">
                    <div className="my-4 text-lg text-center intro-y col-span-12">
                      {props.champsDemandeData.description}
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2">Civilité</div>
                      <select
                        type="text"
                        className="input w-full border flex-1 field"
                        onChange={(e) =>
                          handleInputChange(null, "civilite", e.target.value)
                        }
                      >
                        <option value=""></option>
                        <option value="monsieur">Monsieur</option>
                        <option value="madame">Madame</option>
                        <option value="mademoiselle">Mademoiselle</option>
                      </select>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2">Nom</div>
                      <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Nom"
                        onChange={(e) =>
                          handleInputChange(
                            null,
                            "nom-nouveau-dirigeant",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2">Prénom(s)</div>
                      <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Prénom(s)"
                        onChange={(e) =>
                          handleInputChange(
                            null,
                            "prenoms-nouveau-dirigeant",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {props.champsDemandeData.name === "verifiEntreprise" &&
              props.champsDemandeData.type === "simple" && (
                <div className="grid grid-cols-12 col-span-12 gap-4">
                  <form className="intro-y col-span-12" onSubmit={handleSubmitChecked}>
                    <div className="intro-y col-span-12">
                      {props.champsDemandeData.description}
                    </div>
                    <div className="intro-y col-span-12 flex items-center justify-center">
                      <input
                        type="text"
                        className="input w-full border col-span-8"
                        name="numero-rccm-nom-entreprise"
                        placeholder="Veuillez verifier la disponibilité du nom"
                        onChange={(e) => {
                          handleChecked(e);
                          handleInputChange(null, "numero-rccm-nom-entreprise", e.target.value);
                        }}
                      />
                      <button
                        className="button w-24 justify-center block bg-theme-1 text-white ml-2 sm:col-span-12"
                        type="submit"
                        disabled={isDisabled}
                      >
                        {isDisabled ? "verification..." : "Vérifier"}
                      </button>
                    </div>
                  </form>

                  <div className="intro-y col-span-12 font-medium">
                    {dataEnterprise && dataEnterprise.response?.data?.length > 0 ? (
                      <>
                        <div className="intro-y col-span-12 text-center">
                          <Alert variant="danger">
                            Certaines entreprises ont des noms susceptibles d'être
                            confondus avec celui de votre société. <br />
                            Nous vous encourageons à modifier ou compléter le nom de
                            votre entreprise afin de le rendre distinctif et unique.
                          </Alert>
                        </div>
                        {dataEnterprise.response?.data.map((item, index) => (
                          <div
                            className="intro-y col-span-12 shadow-2xl p-4 my-2 box border-0"
                            key={index}
                          >
                            <div className="grid grid-cols-12">
                              <div className="intro-y col-span-12 md:col-span-11">
                                <div className="intro-y col-span-12 sm:col-span-6 text-2xl">
                                  {item.denomination}
                                </div>
                                <div className="intro-y col-span-12 sm:col-span-6">
                                  <span className="text-lg">RCCM: {item.rccm}</span>
                                </div>
                                <div className="intro-y col-span-12 sm:col-span-6">
                                  <span className="text-lg">Nom commercial: {item.nom_commercial}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}

                    {(dataEnterprise && (dataEnterprise.status !== 200 || dataEnterprise.response.data.length === 0)) ||
                      undefinedState ? (
                      <div className="intro-y col-span-12 text-center">
                        <Alert variant="success">
                          Le nom de votre entreprise a bien été enregistré !
                          Veuillez continuer les étapes suivantes pour finaliser votre inscription.
                        </Alert>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

            {props.champsDemandeData.type === "simple" &&
              props.champsDemandeData.name !== "banque" ? (
              <div className="intro-y col-span-12 sm:col-span-6">
                <input
                  type={props.champsDemandeData.name === "email" ? email : "text"}
                  className="input w-full border flex-1"
                  name={props.champsDemandeData.name}
                  placeholder={props.champsDemandeData.question}
                  onChange={(e) => {
                    setChoice(e.target.value);
                    handleCheck(e);
                  }}
                />
                {props.champsDemandeData.name === "email"
                  ? error && <h2 style={{ color: "red" }}>{error}</h2>
                  : ""}
              </div>
            ) : null}

            {props.champsDemandeData.type === "mutiple" && props.champsDemandeData.name === "capital-social" ? (
              <div className="intro-y col-span-12 sm:col-span-6">
                {/* <div className="mb-2">{props.champsDemandeData.question}</div> */}
                <div className="intro-y col-span-12 flex flex-col">
                  {props.champsDemandeData.options.map((option, index) => (
                    <Fragment key={index}>
                      {option.value === "Autres" && isOther && (
                        <input
                          type="number"
                          className="input w-full border mt-2"
                          placeholder="Veuillez entrer votre propre valeur"
                          onChange={(e) => setChoice(e.target.value)}
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
                {error && <h2 style={{ color: "red" }}>{error}</h2>}
              </div>
            ) : null}






            {props.champsDemandeData.type === "numero" && props.champsDemandeData.name === "numero-telephone" ? (
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="flex">
                  <select
                    className="input w-1/4 border flex-1 mr-2"
                    onChange={(e) => {
                      const newCountryCode = e.target.value;
                      setSelectedCountryCode(newCountryCode);
                      if (newCountryCode && inputPhoneNumber) {
                        const formattedPhoneNumber = `${newCountryCode}${inputPhoneNumber.replace(/\s+/g, '')}`;
                        setChoice(formattedPhoneNumber);
                      } else if (!newCountryCode) {
                        setError("Veuillez sélectionner un indicatif de pays.");
                      }
                    }}
                    value={selectedCountryCode}
                  >
                    <option value="" disabled selected>Sélectionnez un code</option>
                    {countryCodes.map((code, index) => (
                      <option key={index} value={code.code}>
                        {code.label} ({code.code})
                      </option>
                    ))}
                  </select>
                  <input
                    type="tel"
                    className="input w-3/4 border flex-1"
                    name={props.champsDemandeData.name}
                    placeholder="Numéro de téléphone"
                    onChange={(e) => {
                      const newPhoneNumber = e.target.value.replace(/\s+/g, ''); // Retirer les espaces
                      setInputPhoneNumber(newPhoneNumber);
                      if (selectedCountryCode && newPhoneNumber) {
                        const formattedPhoneNumber = `${selectedCountryCode}${newPhoneNumber}`;
                        setChoice(formattedPhoneNumber);
                      } else if (!newPhoneNumber) {
                        setError("Veuillez entrer un numéro de téléphone.");
                      } else {
                        setError(""); // Réinitialiser l'erreur si les conditions sont remplies
                      }
                    }}
                  />
                </div>
                {error && <h2 style={{ color: "red" }}>{error}</h2>}
              </div>
            ) : null}






            {props.champsDemandeData.type === "date" && (
              <div className="intro-y col-span-12 sm:col-span-6">
                <input
                  type={"date"}
                  className="input w-full border flex-1"
                  name={props.champsDemandeData.name}
                  onChange={(e) => {
                    setChoice(e.target.value);
                  }}
                />
                {props.champsDemandeData.name === "email"
                  ? error && <h2 style={{ color: "red" }}>{error}</h2>
                  : ""}
              </div>
            )}

            {props.champsDemandeData.type === "type-employeur" ||
              showNomEntreprise ? (
              <div className="intro-y col-span-12 sm:col-span-6">
                <input
                  type={"text"}
                  className="input w-full border flex-1"
                  placeholder={"Quel est le nom de cette entreprise ?"}
                  onChange={(e) => {
                    setChoiceContrat(e.target.value);
                  }}
                />
                {props.champsDemandeData.name === "type-employeur"
                  ? error && <h2 style={{ color: "red" }}>{error}</h2>
                  : ""}
              </div>
            ) : null}

            {/*Banque*/}

            {props.champsDemandeData.name === "banque" &&
              props.champsDemandeData.type === "simple" ? (
              <div className="intro-y col-span-12 sm:col-span-6">
                <select
                  type="text"
                  className="input w-full border flex-1 field"
                  name={props.champsDemandeData.name}
                  onChange={(e) => {
                    setChoice(e.target.value);
                    setBank(e.target.value);
                  }}
                >
                  <option value={""} className="text-gray-600">
                    Sélectionnez la banque svp
                  </option>
                  {liste_bank?.map((bank, index) => (
                    <option key={index} value={bank?.value}>
                      {bank?.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}

            {props.champsDemandeData.name === "banque" && bank === "Autres" ? (
              <div className="intro-y col-span-12 sm:col-span-6 mt-3">
                <input
                  type={"text"}
                  className="input w-full border flex-1"
                  placeholder={"Veuillez entrer le nom de la banque."}
                  onChange={(e) => {
                    setChoice(e.target.value);
                  }}
                />
              </div>
            ) : null}

            {/*Fin banque*/}

            {showVille ? (
              <div className="intro-y col-span-12 sm:col-span-6">
                <select
                  // type="text"
                  className="input w-full border flex-1 field"
                  onChange={(e) => setChoice(e.target.value)}
                >
                  <option value={0} className="text-gray-600">
                    Quelle est la Commune du siège de votre organisation ?
                  </option>
                  <option value={15_000}>Cocody</option>
                  <option value={15_000}>Marcory</option>
                  <option value={29_000}>Koumassi</option>
                  <option value={29_000}>Adjamé</option>
                  <option value={29_000}>Plateau</option>
                  <option value={44_000}>Yopougon</option>
                  <option value={0}>Autres</option>
                </select>
              </div>
            ) : null}

            <div
              className="intro-y col-span-12 flex items-center justify-center
                        sm:justify-end mt-10"
            >
              {props.step > 1 ? (
                <button
                  className="button w-24 justify-center block bg-gray-200 text-gray-600 
                                dark:bg-dark-1 dark:text-gray-300"
                  onClick={props.handleBackClick ?? ""}
                >
                  Revenir
                </button>
              ) : null}
              <button
                className={`button w-24 justify-center block ml-2 ${isDisabled ? "bg-gray-400 text-gray-700" : "bg-theme-1 text-white"
                  }`}
                onClick={handleNextClick}
              // disabled={isDisabled}  // Disable the button when `isDisabled` is true
              >
                {isLastStepLoading ? "Chargement..." : "Suivant"}
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
import { CI } from ".";
import { Components } from "../../..";
import { Utils } from "../../../../utils";
import { variableExistsInJson } from "../../../../utils/utility";

export function QuestionnaireCiSteps(props) {
  const champs_demandes = props?.data?.useDemande?.champs_demande
    ? JSON.parse(props?.data?.useDemande?.champs_demande)
    : "{}";
  // console.log("champs_demandes", champs_demandes, props.data.step);

  const { type_demande } = props?.data?.useDemande || {};

  const LIBELLE_TYPE_DEMANDE = type_demande?.libelle
    ? type_demande.libelle.split(" ")[
    type_demande.libelle.split(" ").length - 1
    ]
    : "SARL";
  // console.log("LIBELLE_TYPE_DEMANDE", LIBELLE_TYPE_DEMANDE);

  const QuestionnaireFormsByPaysByService = CI
    ? CI[LIBELLE_TYPE_DEMANDE]
    : CI.SARL;

  // console.log(" CI[LIBELLE_TYPE_DEMANDE]", CI[LIBELLE_TYPE_DEMANDE]);
  // console.log(
  //   "QuestionnaireFormsByPaysByService",
  //   QuestionnaireFormsByPaysByService.SigleStep
  // );

  const sigle = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "sigle"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).sigle
    : {};

  const associes = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "associes"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).associes
    : [];

  const associeTypes = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "associeTypes"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).associeTypes
    : "[]";

  const gerants = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "gerants"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).gerants
    : [];

  const presidents = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "presidents"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)?.presidents
    : [];

  const president = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "president"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)?.president
    : {};

  const directeurs = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "directeurs"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)?.directeurs
    : [];
  const adresse = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "adresse"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)?.adresse
    : {};

  const secretaire = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "secretaire"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)?.secretaire
    : {};

  const commissaire_adjoint = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "commissaire-adjoint"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)[
    "commissaire-adjoint"
    ]
    : {};

  const tresorier = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "tresorier"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)?.tresorier
    : {};

  const bureau = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "bureau"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)?.bureau
    : {};

  const commissaire = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "commissaire"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)?.commissaire
    : {};

  const dure = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "dure"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).dure
    : {};

  const montant = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "montant"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).montant
    : {};

  const activites = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "activites"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).activites
    : "";

  const infoEmploi = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "info-emploi"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)["info-emploi"]
    : {};

  const employe = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "employe"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).employe
    : {};

  const responsableEmploi = variableExistsInJson(
    props?.data?.useDemande?.champs_questionnaire,
    "responsable-emploi"
  )
    ? JSON.parse(props?.data?.useDemande?.champs_questionnaire)[
    "responsable-emploi"
    ]
    : {};

  if (LIBELLE_TYPE_DEMANDE === "SARL") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStep
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStep
            index={3}
            step={props.data.step}
            handleNextClick={props?.data?.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={associes ?? []}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites ?? ""}
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          gerants={gerants ?? []}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={8}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "individuelle") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStep
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStep
            index={3}
            step={props.data.step}
            handleNextClick={props?.data?.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          demande={LIBELLE_TYPE_DEMANDE}
          associes={associes ?? []}
        />

        {/* <QuestionnaireFormsByPaysByService.CapitalStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
        /> */}

        <QuestionnaireFormsByPaysByService.ActiviteStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites ?? ""}
        />

        {/* <QuestionnaireFormsByPaysByService.GerantStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          gerants={gerants ?? []}
        /> */}

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={6}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
          demande={LIBELLE_TYPE_DEMANDE}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SARLU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepSarlUni
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>
        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepSarlUni
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.AssocieTypesSarlUni
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={associeTypes}
        />

        <QuestionnaireFormsByPaysByService.AssociesSarlUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          capitalSocial={champs_demandes["capital-social"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
          associe={associes[0] ?? {}}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSarlUni
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites ?? ""}
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          gerants={gerants ?? []}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={8}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SASU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepSasUni
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepSasUni
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.AssocieTypesSasUni
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={associeTypes ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.AssociesSasUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          capitalSocial={champs_demandes["capital-social"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
          associe={associes[0] ?? {}}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSasUni
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites ?? {}}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          president={presidents[0] ?? {}}
        />

        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          directeur={directeurs[0] ?? {}}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={9}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SAS") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepSas
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepSas
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={associes ?? []}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSas
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites ?? ""}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          president={presidents[0] ?? {}}
        />

        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          directeur={directeurs[0] ?? {}}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={9}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "Association") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepAssociation
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <QuestionnaireFormsByPaysByService.AdresseOneStepAssociation
          index={2}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          adresse={adresse ?? {}}
        />

        <QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          adresseNew={
            typeof props?.data?.champs_questionnaireObject["adresse"] !==
              "undefined"
              ? props?.data?.champs_questionnaireObject["adresse"] === ""
                ? "{}"
                : props?.data?.champs_questionnaireObject["adresse"]
              : "{}"
          }
        >
          <Components.OptionalStep
            isOptional={
              props.data.step === 3 &&
              champs_demandes["localisation-siege"]?.includes("Legafrik")
            }
            callback={() =>
              props.data.handleNextClick(new Event("click"), "adresse", "")
            }
          ></Components.OptionalStep>
        </QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation>
        <QuestionnaireFormsByPaysByService.ActiviteStepAssociation
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites ?? ""}
        />
        <QuestionnaireFormsByPaysByService.PresidentAssociation
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          president={president ?? {}}
        />
        <QuestionnaireFormsByPaysByService.SecretaireGeneralAssociation
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          secretaire={secretaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.TresorierAssociation
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          tresorier={tresorier ?? {}}
        />
        <QuestionnaireFormsByPaysByService.BureauAssociation
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          bureau={bureau ?? {}}
          tresorier={tresorier ?? {}}
          president={president ?? {}}
          secretaire={secretaire ?? {}}
        />
        <QuestionnaireFormsByPaysByService.CommissaireAssociation
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          commissaire={commissaire ?? {}}
        />
        <QuestionnaireFormsByPaysByService.CommissaireAssociationAdjoint
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          commissaire_adjoint={commissaire_adjoint ?? "{}"}
        />
        <QuestionnaireFormsByPaysByService.DureAssociation
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          dure={dure ?? {}}
        />
        <QuestionnaireFormsByPaysByService.MontantAssociation
          index={12}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          montant={montant ?? {}}
        />
        <QuestionnaireFormsByPaysByService.RecapStepAssopciation
          index={13}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
    /*    return (
      <div className="grid grid-cols-12 mt-5">

        <QuestionnaireFormsByPaysByService.SigleStepAssociation
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege']?.includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseOneStepAssociation
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
            adresse={adresse??{}}
          /> 
        </Components.OptionalStep>

         <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege']?.includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
            adresseNew={typeof props?.data?.champs_questionnaireObject['adresse']!=="undefined"?(props?.data?.champs_questionnaireObject['adresse']===""?"{}":props?.data?.champs_questionnaireObject['adresse']):"{}"}
          />
        </Components.OptionalStep>

         <QuestionnaireFormsByPaysByService.PresidentAssociation
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          president={president??{}}
        /> 

        <QuestionnaireFormsByPaysByService.SecretaireGeneralAssociation
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          secretaire={secretaire??{}}
        />

        <QuestionnaireFormsByPaysByService.TresorierAssociation
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          tresorier={tresorier??{}}
        />

        <QuestionnaireFormsByPaysByService.BureauAssociation
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          bureau={bureau??{}}
          tresorier={tresorier??{}}
          president={president??{}}
          secretaire={secretaire??{}}

        />

         <QuestionnaireFormsByPaysByService.CommissaireAssociation
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          commissaire={commissaire??{}}
        />
        <QuestionnaireFormsByPaysByService.DureAssociation
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          dure={dure??{}}
        />
         <QuestionnaireFormsByPaysByService.MontantAssociation
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          montant={montant??{}}
        />
        <QuestionnaireFormsByPaysByService.RecapStepAssociation
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          editionLoading={props?.data.editionLoading}
        />  
      </div> 
    );*/
  } else if (LIBELLE_TYPE_DEMANDE === "ONG") {
    // else  {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepOng
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepOng
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepOng
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.PresidentOng
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          president={president ?? {}}
        />

        <QuestionnaireFormsByPaysByService.SecretaireGeneralOng
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          secretaire={secretaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.TresorierOng
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          tresorier={tresorier ?? {}}
        />

        <QuestionnaireFormsByPaysByService.BureauOng
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          bureau={bureau ?? {}}
          tresorier={tresorier ?? {}}
          president={president ?? {}}
          secretaire={secretaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.CommissaireOng
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          commissaire={commissaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.DureOng
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          dure={dure ?? {}}
        />

        <QuestionnaireFormsByPaysByService.MontantOng
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          montant={montant ?? {}}
        />

        <QuestionnaireFormsByPaysByService.RecapStepOng
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "Fondation") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepFondation
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepFondation
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepFondation
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.PresidentFondation
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          president={president ?? {}}
        />

        <QuestionnaireFormsByPaysByService.SecretaireGeneralFondation
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          secretaire={secretaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.TresorierFondation
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          tresorier={tresorier ?? {}}
        />

        <QuestionnaireFormsByPaysByService.BureauFondation
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          bureau={bureau ?? {}}
          tresorier={tresorier ?? {}}
          president={president ?? {}}
          secretaire={secretaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.CommissaireFondation
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          commissaire={commissaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.DureFondation
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          dure={dure ?? {}}
        />
        <QuestionnaireFormsByPaysByService.MontantFondation
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          montant={montant ?? {}}
        />
        <QuestionnaireFormsByPaysByService.RecapStepFondation
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  } else if (
    LIBELLE_TYPE_DEMANDE === "commerciale" ||
    LIBELLE_TYPE_DEMANDE === "civile"
  ) {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepSas
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepSas
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={associes ?? []}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSas
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites ?? ""}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
          bureau={bureau ?? {}}
          tresorier={tresorier ?? {}}
          president={president ?? {}}
          secretaire={secretaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSci
          index={8}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          demande={LIBELLE_TYPE_DEMANDE}
          questionnaireObject={props.data.champs_questionnaireObject}
        />
      </div>
    );

    /*        return (
                    <div className="grid grid-cols-12 mt-5">
                        <QuestionnaireFormsByPaysByService.SigleStep
                            index={1}
                            step={props.data.step}
                            handleNextClick={props.data.handleNextClick}
                            nomSociete={champs_demandes["denomination"]}
                            sigle={sigle??{}}
                        />

                        <Components.OptionalStep
                            isOptional={
                                props.data.step === 2 &&
                                champs_demandes["localisation-siege"]?.includes("Legafrik")
                            }
                            callback={() =>
                                props.data.handleNextClick(new Event("click"), "adresse", "")
                            }
                        >
                            <QuestionnaireFormsByPaysByService.AdresseOneStepSas
                                index={2}
                                step={props.data.step}
                                handleNextClick={props.data.handleNextClick}
                                handleQuestionnaireBackClick={
                                    props.data.handleQuestionnaireBackClick
                                }
                                adresse={adresse??{}}
                            />
                        </Components.OptionalStep>

                        <Components.OptionalStep
                            isOptional={
                                props.data.step === 3 &&
                                champs_demandes["localisation-siege"]?.includes("Legafrik")
                            }
                            callback={() =>
                                props.data.handleNextClick(new Event("click"), "adresse", "")
                            }
                        >
                            <QuestionnaireFormsByPaysByService.AdresseTwoStepSas
                                index={3}
                                step={props.data.step}
                                handleNextClick={props.data.handleNextClick}
                                handleQuestionnaireBackClick={
                                    props.data.handleQuestionnaireBackClick
                                }
                                adresseNew={typeof props?.data?.champs_questionnaireObject['adresse']!=="undefined"?(props?.data?.champs_questionnaireObject['adresse']===""?"{}":props?.data?.champs_questionnaireObject['adresse']):"{}"}

                            />
                        </Components.OptionalStep>

                        <QuestionnaireFormsByPaysByService.Associes
                            index={4}
                            step={props.data.step}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            nombreAssocies={champs_demandes["nombre-associes"]}
                            associes={associes??[]}
                        />

                        <QuestionnaireFormsByPaysByService.ActiviteStepSas
                            index={5}
                            step={props.data.step}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            activites={activites??""}
                        />

                        <QuestionnaireFormsByPaysByService.CapitalStep
                            index={6}
                            step={props.data.step}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            capitalSocial={champs_demandes["capital-social"]}
                            associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}

                        />

                        <QuestionnaireFormsByPaysByService.PresidentStep
                            index={7}
                            step={props.data.step}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
                            demande={LIBELLE_TYPE_DEMANDE}
                            bureau={bureau??{}}
                            tresorier={tresorier??{}}
                            president={president??{}}
                            secretaire={secretaire??{}}
                        />

                        <QuestionnaireFormsByPaysByService.RecapStepSci
                            index={8}
                            step={props.data.step}
                            handleSubmit={props.data.handleSubmit}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            champsDemandeObject={Utils.String.parseJson(
                                props.data.useDemande.champs_demande
                            )}
                            demande={LIBELLE_TYPE_DEMANDE}
                            questionnaireObject={props.data.champs_questionnaireObject}
                        />
                    </div>
                );*/
  } else if (LIBELLE_TYPE_DEMANDE === "Recouvrement") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepRecouvre
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["entreprise-debitrice"]}
          sigle={sigle ?? {}}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepRecouvre
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresse={adresse ?? {}}
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepRecouvre
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              typeof props?.data?.champs_questionnaireObject["adresse"] !==
                "undefined"
                ? props?.data?.champs_questionnaireObject["adresse"] === ""
                  ? "{}"
                  : props?.data?.champs_questionnaireObject["adresse"]
                : "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.FacturesRecouvre
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.CommentRecouvre
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.RecapStepRecouvre
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "statutaire") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.AdresseOneStepSas
          index={1}
          step={props.data.step}
          nomSociete={champs_demandes["denomination"]}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          adresse={adresse ?? {}}
        />

        <QuestionnaireFormsByPaysByService.Associes
          index={2}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          type_demande={type_demande}
          associes={associes ?? []}
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          chamDemande={champs_demandes}
          gerants={gerants ?? []}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          bureau={bureau ?? {}}
          tresorier={tresorier ?? {}}
          president={president ?? {}}
          secretaire={secretaire ?? {}}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={5}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );

    /*        return (
                    <div className="grid grid-cols-12 mt-5">

                        <QuestionnaireFormsByPaysByService.AdresseOneStepSas
                            index={1}
                            step={props.data.step}
                            nomSociete={champs_demandes["denomination"]}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            adresse={adresse??{}}
                        />


                        <QuestionnaireFormsByPaysByService.Associes
                            index={2}
                            step={props.data.step}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            nombreAssocies={champs_demandes["nombre-associes"]}
                            type_demande={type_demande}
                            associes={associes??[]}
                        />

                        <QuestionnaireFormsByPaysByService.GerantStep
                            index={3}
                            step={props.data.step}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
                            chamDemande={champs_demandes}
                            gerants={gerants??[]}
                        />
                        <QuestionnaireFormsByPaysByService.PresidentStep
                            index={4}
                            step={props.data.step}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
                            bureau={bureau??{}}
                            tresorier={tresorier??{}}
                            president={president??{}}
                            secretaire={secretaire??{}}
                        />

                        <QuestionnaireFormsByPaysByService.RecapStepSasV2
                            index={5}
                            step={props.data.step}
                            handleSubmit={props.data.handleSubmit}
                            handleNextClick={props.data.handleNextClick}
                            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
                            champsDemandeObject={Utils.String.parseJson(
                                props.data.useDemande.champs_demande
                            )}
                            questionnaireObject={props.data.champs_questionnaireObject}
                            dataGlobal={props.data ?? "[]"}
                        />
                    </div>
                );*/
  } else if (LIBELLE_TYPE_DEMANDE === "CDI") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.Contrat
          index={1}
          step={props.data.step}
          nomSociete={champs_demandes["denomination"]}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          infoEmploi={infoEmploi}
          employe={employe}
          typeEntreprise={"entreprise"}
          responsableEmploi={responsableEmploi}
        />

        <QuestionnaireFormsByPaysByService.RecapStep
          index={2}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "CDD") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.Contrat
          index={1}
          step={props.data.step}
          nomSociete={champs_demandes["denomination"]}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          infoEmploi={infoEmploi}
          employe={employe}
          typeEntreprise={"particulier"}
          responsableEmploi={responsableEmploi}
        />

        <QuestionnaireFormsByPaysByService.RecapStep
          index={2}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  }
  //else if (LIBELLE_TYPE_DEMANDE === "SCOOP") {
  //   // else  {
  //   return (
  //     <div className="grid grid-cols-12 mt-5">
  //       <QuestionnaireFormsByPaysByService.SigleStepAssociation
  //         index={1}
  //         step={props.data.step}
  //         handleNextClick={props.data.handleNextClick}
  //         nomSociete={champs_demandes["denomination"]}
  //         demande={LIBELLE_TYPE_DEMANDE}
  //       />
  //       <QuestionnaireFormsByPaysByService.AdresseOneStepAssociation
  //         index={2}
  //         step={props.data.step}
  //         handleNextClick={props.data.handleNextClick}
  //         handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //       />

  //       <QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation
  //         index={3}
  //         step={props.data.step}
  //         handleNextClick={props.data.handleNextClick}
  //         handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //         adresseNew={props.data.champs_questionnaireObject["adresse"] ?? "{}"}
  //       >
  //         <Components.OptionalStep
  //           isOptional={
  //             props.data.step === 3 &&
  //             champs_demandes["localisation-siege"]?.includes("Legafrik")
  //           }
  //           callback={() =>
  //             props.data.handleNextClick(new Event("click"), "adresse", "")
  //           }
  //         ></Components.OptionalStep>
  //       </QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation>
  //       <QuestionnaireFormsByPaysByService.Associes
  //         index={4}
  //         step={props.data.step}
  //         handleNextClick={props.data.handleNextClick}
  //         handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //         nombreAssocies={champs_demandes["nombre-associes"]}
  //       />
  // <QuestionnaireFormsByPaysByService.CapitalStep
  //   index={5}
  //   step={props.data.step}
  //   handleNextClick={props.data.handleNextClick}
  //   handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //   capitalSocial={champs_demandes["capital-social"]}
  //   associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
  // />
  //       <QuestionnaireFormsByPaysByService.ActiviteStepAssociation
  //         index={6}
  //         step={props.data.step}
  //         handleNextClick={props.data.handleNextClick}
  //         handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //       />
  // <QuestionnaireFormsByPaysByService.PresidentAssociation
  //   index={7}
  //   step={props.data.step}
  //   handleNextClick={props.data.handleNextClick}
  //   handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //   demande={LIBELLE_TYPE_DEMANDE}
  //   // nombreAssocies={champs_demandes["nombre-associes"]}
  //   associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
  // />
  // <QuestionnaireFormsByPaysByService.SecretaireGeneralAssociation
  //   index={8}
  //   step={props.data.step}
  //   handleNextClick={props.data.handleNextClick}
  //   handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //   demande={LIBELLE_TYPE_DEMANDE}
  //   // nombreAssocies={champs_demandes["nombre-associes"]}
  //   associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
  // />

  // <QuestionnaireFormsByPaysByService.TresorierAssociation
  //   index={9}
  //   step={props.data.step}
  //   handleNextClick={props.data.handleNextClick}
  //   handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //   capitalSocial={champs_demandes["capital-social"]}
  //   demande={LIBELLE_TYPE_DEMANDE}
  //   // nombreAssocies={champs_demandes["nombre-associes"]}
  //   associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
  // />
  // <QuestionnaireFormsByPaysByService.PresidentCommision
  //   index={10}
  //   step={props.data.step}
  //   handleNextClick={props.data.handleNextClick}
  //   handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //   capitalSocial={champs_demandes["capital-social"]}
  //   demande={LIBELLE_TYPE_DEMANDE}
  //   // nombreAssocies={champs_demandes["nombre-associes"]}
  //   associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
  // />

  // <QuestionnaireFormsByPaysByService.DureAssociation
  //   index={11}
  //   step={props.data.step}
  //   handleNextClick={props.data.handleNextClick}
  //   handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //   handleSubmit={props.data.handleSubmit}
  //   dataGlobal={props.data ?? "[]"}
  //   demande={LIBELLE_TYPE_DEMANDE}
  //   associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
  // />

  // <QuestionnaireFormsByPaysByService.Rapporteurs
  //   index={12}
  //   step={props.data.step}
  //   handleNextClick={props.data.handleNextClick}
  //   handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //   demande={LIBELLE_TYPE_DEMANDE}
  //   // nombreAssocies={champs_demandes["nombre-associes"]}
  //   associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
  // />
  //       <QuestionnaireFormsByPaysByService.RecapStepScoop
  //         index={13}
  //         step={props.data.step}
  //         handleNextClick={props.data.handleNextClick}
  //         handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
  //         questionnaireObject={props.data.champs_questionnaireObject}
  //         handleSubmit={props.data.handleSubmit}
  //         dataGlobal={props.data ?? "[]"}
  //         demande={LIBELLE_TYPE_DEMANDE}
  //       />
  //     </div>
  //   );
  // }

  else if (LIBELLE_TYPE_DEMANDE === "SCOOP") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepOng
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle ?? {}}
        />


        <QuestionnaireFormsByPaysByService.AdresseOneStepSasUni
          index={2}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={
            props.data.handleQuestionnaireBackClick
          }
          adresse={adresse ?? {}}
        />
        <QuestionnaireFormsByPaysByService.AdresseTwoStepSasUni
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={
            props.data.handleQuestionnaireBackClick
          }
          adresseNew={
            typeof props?.data?.champs_questionnaireObject["adresse"] !==
              "undefined"
              ? props?.data?.champs_questionnaireObject["adresse"] === ""
                ? "{}"
                : props?.data?.champs_questionnaireObject["adresse"]
              : "{}"
          }
        />
        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        {/* <QuestionnaireFormsByPaysByService.AssocieTypesSasUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={associeTypes ?? "[]"}
        /> */}
        <QuestionnaireFormsByPaysByService.CapitalStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.ActiviteStepSasUni
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites ?? {}}
        />
        {/* <QuestionnaireFormsByPaysByService.DirecteurStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          directeur={directeurs[0] ?? {}}
        /> */}
        <QuestionnaireFormsByPaysByService.PresidentAssociation
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          demande={LIBELLE_TYPE_DEMANDE}
          // nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.SecretaireGeneralAssociation
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          demande={LIBELLE_TYPE_DEMANDE}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.TresorierAssociation
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          demande={LIBELLE_TYPE_DEMANDE}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.PresidentCommision
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          demande={LIBELLE_TYPE_DEMANDE}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.Rapporteurs
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          demande={LIBELLE_TYPE_DEMANDE}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.DureAssociation
          index={12}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.RecapStepScoop
          index={13}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />

      </div>
    );
  }

  return <div>Pas de questionnaire</div>;
}

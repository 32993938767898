export const DemandeLabels = {
  "nombre-associes": "Le nombre d'associés",
  banque: "Banque",
  "entreprise-anterieur": "Avez-vous déjà créé une entreprise",
  "date-reception-certificat": "Date désirée pour la réception du certificat",
  "localisation-siege": "Localisation du siège de votre entreprise ?",
  "capital-social": "Capital social",
  "est-president": "Est-il le président",
  offre: "Offre",
  "gestion-domiciliation": "Domiciliation",
  "gestion-dfe": "Déclaration Fiscale D'existence",
  "gestion-graphique": "Identité visuelle",
  "gestion-facture-normalisees": "Facture normalisée",
  "solution-pay": "Gestion comptable",
  email: "Email",
  nom: "Nom",
  prenoms: "Prénom(s)",
  "numero-telephone": "Numéro de téléphone",
  monnaie: "Monnaie utilisée",
  ville: "Ville",
  "code-postale": "Numéro de boîte postale",
  adresse: "Adresse",
  quartier: "Quartier",
  commune: "Commune",
  lot: "Lot",
  ilot: "Ilot",
  section: "Numéro de section",
  parcelle: "Numéro de parcelle",
  "nom-proprietaire": "Nom du propriétaire",
  "prenoms-proprietaire": "Prénom(s) du propriétaire",
  "numero-telephone-proprietaire": "Numéro téléphone propriétaire",
  type: "Nature",
  civilite: "Civilité",
  "type-piece-identite": "Nature de la pièce d’identité",
  "numero-piece-identite": "Numéro de la pièce",
  "date-naissance": "Date de naissance de l'associé",
  "lieu-naissance": "Lieu de naissance de l'associé",
  "pays-naissance": "Pays de naissance de l'associé",
  nationalite: "Nationnalite de l'associé",
  "secteur-activite": "Secteur d’activités",
  sigle: "Sigle",
  "situation-matrimoniale": "Situation matrimoniale",
  "regime-matrimonial": "Régime matrimonial",
  apport: "Apport de l'associé",
  denomination: "Dénomination",
  "forme-sociale": "Forme sociale",
  "capital-sociale": "Capitale social",
  "siege-sociale": "Siège social",
  "numero-sociale": "Numéro registre de commerce",
  "ville-immatriculation": "Ville immatriculation",
  "nom-representant": "Nom du représentant",
  "prenoms-representant": "Prénom(s) du représentant",
  "civilite-representant": "Civilité du représentant",
  "associe-gerant": "L'associé est-il un gérant ?",
  "nom-pere": "Nom et prenom(s) du père",
  "nom-mere": "Nom et prenom(s) de la mère",
  premiere_proposition: "1ere proposition de nom",
  deuxieme_proposition: "2eme proposition de nom",
  troisieme_proposition: "3eme proposition de nom",
  sigle_obligatoire: "Le sigle est-il obligatoire",
  "Numero telephone": "Numéro de téléphone",
  "Secteur activite": "Secteur d’activités",
  Prenoms: "Prénom(s)",
  "Entreprise anterieur": "Entreprise anterieure",
  "Localisation siege": "Localisation du siège",
  "Gestion dfe": "Gestion DFE",
  "Gestion facture normalisees": "Gestion facture normalisée",
  Denomination: "Dénomination",
  "Nombre associes": "Nombre d'associés",
  "Date reception certificat": "Date de réception certificat",
  "date-piece-identite": "Date d'établissement de la pièce",
  "date-fin-validite-piece-identite": "Date de validité de la pièce",
  "pays-piece-identite": "Pays d'établissement de la pièce",
  Statut: "Status",
  "numero-boite-postale": "Numéro de boite postale",
  "associe-president": "Le président a t'il associé(s) ?",
  "fait-partie-des-associes": "Le président fait il partie des associes ?",
  activites: "activité(s)",
  Nationalite: "Nationalité",
  "civilite-proprietaire": "Civilité du proprietaire",
  "mandat-nom": "Nom du mandat",
  "numero-telephone-interlocuteur": "Numéro de téléphone de l'interlocuteur",
  "civilite-interlocuteur": "Civilité de l'interlocuteur",
  "prenom-interlocuteur": "Prénom(s) de l'interlocuteur",
  "nom-interlocuteur": "Nom de l'interlocuteur",
  "email-interlocuteur": "E-mail de l'interlocuteur",
  "nombre-de-voies": "Nombre de voies",
  "type-mandat": "Type de mandat",
  "numero-piece-proprietaire": "Numéro de la pièce de propriétaire",
  "nombre-de-part-detenue": "Nombre de part détenue",
  "type-president": "Type du président",
  "montant-droit": "Montant du droit d’adhésion",
  "montant-cotisation": "Montant des cotisations annuelles",
  "type-secretaire": "Type du secrétaire",
  "type-tresorier": "Type du trésorier",
  "nom-vice-president": "Nom du vice-président",
  "nationalite-vice-president": "Nationalité du vice-président",
  "numero-vice-president": "Numéro du vice-président",
  "nom-secretaire-adjoint": "Nom du secrétaire adjoint",
  "nationalite-secretaire-adjoint": "Nationalité du secrétaire adjoint",
  "numero-secretaire-adjoint": "Numéro du secrétaire adjoint",
  "nom-tresorier-adjoint": "Numéro du trésorier adjoint",
  "nationalite-tresorier-adjoint": "Nationalité du trésorier adjoint",
  "numero-tresorier-adjoint": "Numéro du trésorier adjoint",
  "dure-bureau": "Durée du mandat du bureau exécutif",
  "dure-commisaire": "Durée du mandat des commissaires aux comptes",
  "modification-du-statut": "Modification du statut",
  "valeur-de-la-modification": "Valeur de la modification",
  "vendeur-part": "La part du vendeur",
  "dure-president": "Durée du président",
  "nom-commissaire": "Nom du commissaire",
  "nom-commisaire-adjoint": "Nom du commissaire adjoint",
  "numero-commissaire-adjoint": "Numéro du commissaire adjoint",
  "nationalite-commissaire-adjoint": "Nationalité du commissaire adjoint",
  "numero-commissaire": "Numéro du commissaire",
  "categorie-professionne": "Catégorie professionnelle",
  "civilite-maitre-emploi": "Civilité",
  "nom-maitre-emploi": "Nom de l’employeur",
  "prenoms-maitre-emploi": "Prénoms de l’employeur",
  "civilite-tuteur": "Civilité du tuteur",
  "nom-tuteur": "Nom du tuteur",
  "prenoms-tuteur": "Prénoms du tuteur",
  "categorie-professionnel": "Catégorie professionnelle",
  "grade-categorie-professionnel": "Grade de la catégorie",
  "missions": "Liste des taches et missions",
  "debut-emploi": "Date début contrat",
  "fin-emploi": "Date fin contrat",
  "duree-essaie": "Date fin contrat",
  "lieu-de-emploi": "Date fin contrat",
  "duree-de-travail": "Temps de travail",
  "jours-de-travail": "Jours de travail",
  "salaire-en-chiffre": "Montant du salaire",
  "ville-signature": "Ville de signature",
  "lieu-emploi": "Lieu emploi",
  "heure-de-fin": "Heure de fin",
  "service-employe": "Service de l'employé",
  "duree-emploi": "Duree de l'emploi",
  "heure-de-travail": "Heure de travail",
  "salaire-en-lettre": "Salaire en lettre",
};

import React, { useEffect, useState } from "react";
import * as Icons from "react-feather";
import ApiService from "../../api/services/api.service";

function LogListe() {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const ELEMENT_PER_PAGE = 10;

  const apiUrl = new ApiService();

  useEffect(() => {
    fetchLogs();
  }, [page]);

  const fetchLogs = async () => {
    try {
      const response = await apiUrl._get(`admin/logs?page=${page}`);
      const { logs, success, total_pages } = response.data;

      if (success && Array.isArray(logs)) {
        setLogs(logs);
        setTotalPage(total_pages);
      } else {
        setLogs([]);
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      setLogs([]);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPage) {
      setPage(page + 1);
    }
  };

  return (
    <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
      <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center my-2 justify-between">
        <div className="p-5 text-xl font-medium">Les Logs</div>
      </div>
      <table className="table table-report mt-6">
        <thead>
          <tr>
            <th>Opérations</th>
            <th>Utilisateur Authentifié</th>
            <th>Rôle</th>
            <th>Date</th>
            <th>Heure</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {logs && logs.length > 0 ? (
            logs.map((log) => (
              <tr key={log.id}>
                <td>{log?.operation || "Non défini"}</td>
                <td>{log?.admin.username || "Non défini"}</td>
                <td>{log?.admin.role?.libelle || "Non défini"}</td>
                <td>{log?.timestamp ? new Date(log.timestamp).toLocaleDateString() : "Non défini"}</td>
                <td>{log?.timestamp ? new Date(log.timestamp).toLocaleTimeString() : "Non défini"}</td>
                <td>{log?.status || "Non défini"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                Pas de logs disponibles.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="px-5 py-3 flex justify-end text-right border-t border-gray-200">
        <button
          onClick={handlePreviousPage}
          type="button"
          className="button flex w-36 items-center border bg-theme-1 text-white"
          disabled={page === 1}
        >
          <Icons.ArrowLeft />
          <span>Précédent</span>
        </button>
        <div className="bg-white text-gray-600 bold mx-5 p-3 box">
          <span>
            {page} / {totalPage}
          </span>
        </div>
        <button
          onClick={handleNextPage}
          type="button"
          className="button flex w-36 items-center border bg-theme-1 text-white"
          disabled={page === totalPage}
        >
          <span>Suivant</span>
          <Icons.ArrowRight />
        </button>
      </div>
    </div>
  );
}

export default LogListe;
